import React from 'react';
import logo from './logo.svg';
import Home from './Home';
import './App.css';


const App = () => {
  return (
   <Home />
  );
};
export default App;
